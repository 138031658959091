@import '../../../../node_modules/react-calendar/dist/Calendar.css';

/* Main container */
.react-calendar {
  width: 100%;
  background: transparent;
  border: none;
  font-family: 'Montserrat Regular';
}

/*  */
.react-calendar__navigation {
  display: flex;
  width: 60%;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 6px;
  border: 1px solid #e5b817;
  border-radius: 8px;
  margin-bottom: 12px;
  height: 39px;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none !important;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next-button,
.react-calendar__navigation__label {
  color: #e5b817;
  font-family: 'Montserrat Bold';
  font-size: 0.8rem;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}

.react-calendar__tile--active:focus,
.react-calendar__tile--active:active,
.react-calendar__tile--active:enabled,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus,
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: transparent;
}

.react-calendar__tile--active:enabled:focus > abbr,
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__tile--active > abbr {
  border-color: #e5b817 !important;
  color: #e5b817;
}

.react-calendar__tile:disabled {
  background-color: transparent;
  color: #797979 !important;
  cursor: auto;
}

.react-calendar__tile:disabled:hover > abbr {
  border-color: transparent !important;
}

.react-calendar__navigation button[disabled] {
  background-color: transparent;
}

.react-calendar__month-view__weekdays,
.react-calendar__year-view__months__month,
.react-calendar__century-view__decades__decade,
.react-calendar__decade-view__years__year {
  color: white;
}

abbr[title] {
  text-decoration: none !important;
}

.react-calendar__tile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-calendar__tile > abbr,
.react-calendar__tile.react-calendar__century-view__decades__decade,
.react-calendar__tile.react-calendar__decade-view__years__year {
  padding: 6px;
  border: 1px solid transparent;
  border-radius: 28px;
  width: 32px;
  height: 32px;
  margin-bottom: -8px;
}

.react-calendar__tile:hover abbr,
.react-calendar__tile--active,
.react-calendar__century-view__decades__decade,
.react-calendar__decade-view__years__year {
  border-color: #e5b817;
}

.react-calendar__tile:hover {
  background-color: transparent !important;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  color: #797979;
}

.react-calendar__tile.react-calendar__month-view__days__day {
  color: white;
  font-size: 0.9rem;
}

.react-calendar__tile--now {
  background-color: transparent;
}

.react-calendar__year-view__months__month > abbr,
.react-calendar__century-view__decades__decade,
.react-calendar__decade-view__years__year {
  width: 100%;
}

.react-calendar__tile.react-calendar__century-view__decades__decade,
.react-calendar__tile.react-calendar__decade-view__years__year {
  margin: 16px 0;
}
